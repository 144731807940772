import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

// https://www.google.com/maps/place/2800+Oneida+St,+Denver,+CO+80207/data=!4m2!3m1!1s0x876c7b8e5b983da5:0x3d3b3b4c398730f5?sa=X&ved=2ahUKEwitx4eCke_tAhWF1FkKHdagAjQQ8gEwAHoECAQQAQ

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/jesse-brown.png";
  const fullName = "Jesse Brown";
  const zoomUrl =
    "https://us02web.zoom.us/j/87230775479?pwd=QldHRlc0SktWSkhlR3BEMXFxRlNoUT09";
  const zoomPhoneNumber = "(301) 715-8592";
  const zoomMeetingId = "872 3077 5479";
  const zoomMeetingPasscode = "100091";
  const birthDate = "January 12, 1962";
  const deathDate = "December 4, 2020";
  const memorialDate = "Saturday January 2nd, 2021";
  const memorialTime = "1:00PM MST";

  const inviteText =
    "Due to the current challenges we all face and the reason I have lost my loved one. In lieu of a physical ceremony, the family and friends of Jesse Brown invite you to a virtual service honoring his life.  The family would like to invite you to a meal for all those who would like to say there condolences to the family or to drop off sympathy cards and to get a copy of the obituary. Again due to this ongoing virus this will be will a drive by repast meal from 3 to 4 pm following the virtual service at 2800 Oneida St Denver Colorado.";
  const obituary = `Jesse Brown was born on January 12, 1962 in Cleveland, Ohio to Mr. Willie Brown and Mrs. Annabell Brown. Jesse was the youngest of three children. The family moved to Colorado in October of 1967. Jesse received his education at George Washington High School in Denver, Colorado. He worked at the City and County of Denver for 25 years before retiring in 2018.
  \nJesse was married to Veronica Meadows on June 19, 2004 in Denver, Colorado at the Northeast church of Christ. Jesse loved to socialize and spend time with family and friends while cooking ribs and often playing a friendly game of cards. Jesse was an avid sports fan. He especially loved football and the Broncos, but his favorite pastime was bowling. Jesse enjoyed bowling and spent many years on leagues and in tournaments. If Jesse could, he would have spent most of his time in the bowling alley doing what he loved most, bowling. 
  \nJesse departed this life on December 4, 2020 in Denver, Colorado after battling Covid-19. Jesse was preceded in death by his father Willie Brown Sr., his mother Annabell Brown and his sister Shirley Ann McKenzie. Jesse is survived by his wife Veronica Brown; his three children, Sunny Green, Jesse Brown Jr., and Kaylee; his sister Gwendolyn Smith and brother Willie Brown Jr.; grandchildren Angie, Xavier and Jasirer; nieces and nephews, relatives and friends. He will be missed by all who knew and loved him.`;
  const storiesRecordUrl = "https://stories.afterword.com/fgchuq4nj";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rcxv63ixs4n7z9boqjet4gdu963xy8hesd16uqf1";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/JesseBrownRecording720.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
    ></StoryPage>
  );
};

export default ThisPage;
